<template>
  <centered-layout v-if="object && objectType" content-class="">
    <template #header class="pb-16 pt-5">
      <slot name="header"></slot>
    </template>
    <template #content>
      <image-carousel
        v-if="computedImages && computedImages.length"
        :with-modal-preview="withImageModalPreview"
        :images="computedImages"
        :cover-img="false"
        fill-background-with-pattern
      />
      <div class="mb-12 mt-4 flex items-stretch">
        <div
          class="flex w-full items-start flex-col md:flex-row p-5 gap-5 md:gap-10"
        >
          <div class="details-wrapper">
            <object-info :object="object" :place-preview="placePreview" />
          </div>

          <price-box
            :offer-object="offerObject"
            class="flex-shrink-0 flex-grow-0"
          />
        </div>
      </div>
      <div class="map-wrapper">
        <p class="text-2xl pt-5 pl-5">
          {{ $t('object-details-view.localization') }}
        </p>
        <object-map />
      </div>
    </template>
  </centered-layout>
</template>

<script>
import CenteredLayout from '@/layouts/CenteredLayout';
import ObjectInfo from '@/views/Brand/Objects/Components/ObjectInfo';
import ObjectMap from '@/views/Brand/Objects/Components/ObjectMap';
import { mapState } from 'vuex';
import ImageCarousel from '@/components/ImageCarousel/ImageCarousel';
import { extension } from '@/util/formatters';
import { isMultimediaFile } from '@/util/files';
import PriceBox from '@/views/Brand/Objects/Components/PriceBox';
import { ObjectDetailsPlacePreview } from '@/views/Brand/Objects/_constants';

export default {
  components: {
    PriceBox,
    CenteredLayout,
    ObjectMap,
    ObjectInfo,
    ImageCarousel,
  },
  props: {
    offerObject: {
      type: Boolean,
      default: false,
    },
    placePreview: {
      type: String,
      default: ObjectDetailsPlacePreview.POPOVER,
    },
    withImageModalPreview: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('object', {
      object: 'details',
      objectType: 'objectType',
    }),
    ...mapState('attachment', ['attachments']),
    computedImages() {
      const mainImage = {
        url: this.object.cdnImageUrl,
        name: 'object image',
      };

      const multimediaAttachments = this.attachments.multimedia.filter(
        (media) => isMultimediaFile(extension(media.url)),
      );

      const includeMainImage =
        this.object.cdnImageUrl || !multimediaAttachments.length;

      return [
        includeMainImage && mainImage,
        ...this.attachments.multimedia.filter((media) =>
          isMultimediaFile(extension(media.url)),
        ),
      ].filter(Boolean);
    },
    ...mapState('offer', ['currentOffer']),
  },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
  @apply flex justify-between items-start flex-grow flex-shrink w-full;
}

.map-wrapper {
  @apply border-t border-gray-light;
}
</style>
