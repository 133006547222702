<template>
  <div>
    <mention
      v-model="mentions"
      :input-value="value"
      :url="usersUrl"
      :query="query"
      :cursor-position="cursorPosition"
      @mention-user="mentionUser"
    >
      <div
        class="comment"
        :class="{
          disabled,
          'border-solid border-t border-gray-light': !isSimplified,
        }"
      >
        <div v-if="!isSimplified" class="comment-toolbar">
          <span class="btn-group">
            <button
              v-for="(button, i) in styleButtons"
              :key="i"
              @click="insertWrapper(button)"
            >
              <component :is="button">{{ button.toUpperCase() }}</component>
            </button>
          </span>
          <button :disabled="disabled" @click="insert('@', 1)">@</button>
          <button :disabled="disabled" @click="insert('\n', 2)">↵</button>
        </div>

        <textarea
          ref="commentInput"
          v-model="value"
          :class="!isSimplified ? 'comment-input' : 'comment-input-simplified'"
          :rows="2"
          :disabled="isLoading || disabled"
          :placeholder="$t('comments.add')"
          @keydown.enter="onEnter"
          @input="cursorPosition = input.selectionStart"
          @focus="cursorPosition = input.selectionStart"
        />
        <loading-spinner
          v-if="isLoading"
          class="comment-spinner"
          :is-loading="isLoading"
        />
      </div>
    </mention>
    <p v-if="mentions.length" class="text-gray-dark text-xs mt-2 px-2">
      {{ $t('comments.mentions') }}:
      <span class="text-primary">
        {{
          relatedUsers
            .map((user) => `${user.firstName} ${user.lastName}`)
            .join(', ')
        }}
      </span>
    </p>
  </div>
</template>
<script>
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';
import Mention from '@/components/Mention/Mention';
import textAreaMentionMixin from '@/mixins/textAreaMentionMixin';

export default {
  components: { LoadingSpinner, Mention },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    usersUrl: {
      type: String,
      required: true,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    isSimplified: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [textAreaMentionMixin],
  mounted() {
    this.input = this.$refs.commentInput;
  },
  methods: {
    onEnter(e) {
      if (e.shiftKey) return;
      e.preventDefault();
      this.$emit('add-comment', this.formattedValue);
    },
  },
};
</script>
<style scoped lang="scss">
.comment {
  @apply bg-white box-border flex flex-col justify-between relative;

  &-toolbar {
    @apply p-2;

    .btn-group {
      @apply border-r border-solid border-gray-dark;
    }

    button {
      @apply font-semibold px-3 text-gray-darker;
    }
  }

  &-input {
    @apply pt-2.5 pb-4 pl-4 pr-2 w-full rounded outline-none text-gray-darkest resize-none box-border;
    &::placeholder {
      @apply text-gray-darker;
    }
  }

  &-input-simplified {
    @apply p-2 w-full rounded outline-none text-gray-darkest text-2xs resize-none box-border border border-gray-light;
    &::placeholder {
      @apply text-gray-darker;
    }
  }

  &-spinner {
    @apply absolute w-6 h-6;
    bottom: 1.5rem;
    right: 0.5rem;
  }

  &.disabled {
    @apply bg-gray-lighter;

    button {
      @apply pointer-events-none;
    }
  }
}
</style>
