import { computed, defineComponent, onUnmounted, watch, } from '@vue/composition-api';
import FetchData from '@/components/DataProvider/FetchData.vue';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
import { useBrand, useRouteQuery, useUi } from '@/composables';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import ActionRequireMessageItem from '@/views/Brand/Applications/Report/_components/action-required-messages/action-require-message-item/action-require-message-item.vue';
import TablePagination from '@/views/_components/Table/TablePagination.vue';
import { REPORTS_TAB_NAME, ReportTab, reportTabIndexMap, } from '@/views/Brand/Applications/Components/ReportMenu/ReportMenuItems.constants';
export default defineComponent({
    components: {
        TablePagination,
        ActionRequireMessageItem,
        LoadingSpinner,
        FetchData,
    },
    setup(_, ctx) {
        const { brand } = useBrand();
        const { updatesCounter, update } = useUpdatesCounter();
        const { routeQuery } = useRouteQuery(ctx);
        const { setTab } = useUi();
        onUnmounted(() => {
            setTab({
                name: REPORTS_TAB_NAME,
                index: reportTabIndexMap[ReportTab.REPORTS_LIST],
            });
        });
        watch(() => routeQuery.value.q, () => {
            update();
        });
        const query = computed(() => {
            return {
                page: routeQuery.value.page,
                limit: 10,
                brandId: brand.value.id,
                sortBy: '-requiredActionMarkedAt',
                q: routeQuery.value.q,
            };
        });
        return {
            query,
            updatesCounter,
            update,
        };
    },
});
