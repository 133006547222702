import ReportsSidebar from '@/views/Brand/Applications/Components/Sidebar/Sidebar.vue';
import PopupLayout from '@/layouts/PopupLayout/PopupLayout.vue';
import AddTask from '@/views/Brand/Applications/Report/AddTask.vue';
import EditTask from '@/views/Brand/Applications/Report/EditTask.vue';
import { computed, defineComponent } from '@vue/composition-api';
import { PortalTarget } from '@/constants/portal';
import { ReportMobileNavigation } from '@/views/Brand/Applications/Components/ReportMenu';
import { FloatingActionButton } from '@/components/FloatingActionButton';
import { mdiPlus } from '@mdi/js';
import { useRouteQuery } from '@/composables';
import { REPORTS_LIST_ROUTE_NAME } from '@/views/Brand/Applications/Components/Sidebar/sidebar.constants';
export default defineComponent({
    components: {
        FloatingActionButton,
        ReportMobileNavigation,
        AddTask,
        EditTask,
        ReportsSidebar,
        PopupLayout,
    },
    props: {
        editedReport: String,
        showResourcesToggler: Boolean,
        showResources: Boolean,
        fillScreen: Boolean,
        showPopup: {
            type: Boolean,
            default: false,
        },
        closeReportRequest: {
            type: Number,
            default: 0,
        },
        closeReportCallback: {
            type: Function,
            default: () => { },
        },
        hideFilters: {
            type: Boolean,
            default: false,
        },
    },
    setup(_, ctx) {
        const { routeQuery, selectLimit } = useRouteQuery(ctx);
        const isReportListView = computed(() => ctx.root.$route.name === REPORTS_LIST_ROUTE_NAME);
        return { PortalTarget, mdiPlus, isReportListView, routeQuery, selectLimit };
    },
});
