<template>
  <div>
    <div class="min-h-10 max-h-40 overflow-y-scroll">
      <infinite-data
        :key="updateComments"
        :url="url"
        class="h-full"
        direction="top"
        container-selector="#chat-container"
        :should-fetch="shouldFetch"
        @data-deliver="getData"
      >
        <comment-list-offer
          v-if="comments"
          id="chat-container"
          :comments="comments"
          :comment-added="commentAdded"
          @scroll-finished="shouldFetch = true"
        />
        <p v-else class="text-gray-dark m-4 text-sm">
          {{ $t('comments.no-messages') }}
        </p>
      </infinite-data>
    </div>
    <comment-input
      ref="commentInput"
      is-simplified
      :is-loading="loadingComment"
      :disabled="disabled"
      :success="commentAdded"
      :users-url="usersUrl"
      :query="query"
      @add-comment="onAddComment"
    />
  </div>
</template>
<script>
import CommentInput from '@/components/Comment/CommentInput';
import CommentListOffer from '@/components/Comment/CommentListOffer';
import InfiniteData from '@/components/DataProvider/InfiniteData.vue';

export default {
  name: 'CommentModuleOffer',
  components: {
    CommentInput,
    CommentListOffer,
    InfiniteData,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    usersUrl: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    forceUpdate: {
      type: [Number, Boolean],
      default: false,
    },
    addCommentHandler: {
      type: Function,
      required: true,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      updateComments: 0,
      loadingComment: false,
      commentAdded: false,
      inputHeight: 0,
      comments: null,
      shouldFetch: false,
    };
  },
  mounted() {
    this.inputHeight =
      this.$refs.commentInput.$el.getBoundingClientRect().height;
  },
  methods: {
    async onAddComment(message) {
      this.loadingComment = true;
      this.commentAdded = false;

      try {
        await this.addCommentHandler(message);

        this.commentAdded = true;
        this.updateComments++;
      } catch {
        this.commentAdded = false;
      } finally {
        this.loadingComment = false;
      }
    },
    getData(data) {
      this.comments = data;
    },
  },
};
</script>
