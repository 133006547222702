export const isInViewport = (element, container) => {
    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    return (elementRect.top >= containerRect.top &&
        elementRect.bottom <= containerRect.bottom &&
        elementRect.left >= containerRect.left &&
        elementRect.right <= containerRect.right);
};
export const scrollToBottom = (container) => {
    container.scrollTo({
        top: container.scrollHeight,
    });
};
