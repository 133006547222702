export default {
  data() {
    return {
      value: '',
      input: null,
      mentions: [],
      cursorPosition: 1,
      styleButtons: ['b', 'i', 'u'],
    };
  },
  computed: {
    formattedValue() {
      let value = this.value.replace(/\n/g, '<br />');

      this.mentions.forEach(({ firstName, lastName, id }) => {
        value = value.replace(`@${firstName}_${lastName}`, `@${id}`);
      });

      return value;
    },
    relatedUsers() {
      const users = [];

      this.mentions.forEach((mention) => {
        if (!users.find((user) => user.id === mention.id)) users.push(mention);
      });

      return users;
    },
  },
  watch: {
    isLoading(value) {
      if (!value && this.success) this.value = '';
    },
  },
  methods: {
    insertWrapper(marker) {
      this.insert(`<${marker}></${marker}>`, marker.length + 2);
    },
    insert(str, cursorOffset = 1) {
      const newCursorPosition = this.cursorPosition + cursorOffset;

      this.value = this.getValueWithInsert(str);

      this.input.focus();

      this.$nextTick(() => {
        this.changeCursorPosition(newCursorPosition);
      });

      this.scroll();
    },
    getValueWithInsert(string) {
      const start = this.value.slice(0, this.cursorPosition);
      const end = this.value.slice(this.cursorPosition);

      return `${start}${string}${end}`;
    },
    changeCursorPosition(index) {
      this.cursorPosition = index;

      this.input.selectionStart = index;
      this.input.selectionEnd = index;
    },
    scroll() {
      this.input.scrollTop = this.input.scrollHeight;
    },
    mentionUser(value) {
      this.value = value;

      this.input.focus();
    },
  },
};
