export var IconSize;
(function (IconSize) {
    IconSize["XXS"] = "xxs";
    IconSize["XS"] = "xs";
    IconSize["SM"] = "sm";
    IconSize["MD"] = "md";
    IconSize["LG"] = "lg";
    IconSize["XL"] = "xl";
})(IconSize || (IconSize = {}));
export const ICON_SIZE_MAP = {
    [IconSize.XXS]: '0.75rem',
    [IconSize.XS]: '1rem',
    [IconSize.SM]: '1.25rem',
    [IconSize.MD]: '1.5rem',
    [IconSize.LG]: '1.75rem',
    [IconSize.XL]: '2rem',
};
