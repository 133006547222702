import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { getStructuresList } from '@/api/requests/structures';
import { BudgetTreeNode } from '@/views/Brand/Places/_components/budget-tree-node';
import { BudgetSummary } from '@/views/Brand/Places/_components/budget-summary';
import { useAuthUser, useBrand, useRouteQuery } from '@/composables';
import BackdropLoader from '@/components/BackdropLoader/BackdropLoader.vue';
import { SystemRole } from '@/api/requests/users';
import { getObjectTypeByBudget } from '@/api/requests/object-types';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
export default defineComponent({
    components: { BackdropLoader, BudgetSummary, BudgetTreeNode },
    setup(_, ctx) {
        const objectType = ref(null);
        const params = ctx.root.$route.params;
        const structures = ref([]);
        const rootStructure = ref(null);
        const { brand } = useBrand();
        const isLoading = ref(false);
        const { hasRole, authUserId } = useAuthUser();
        const { update, updatesCounter } = useUpdatesCounter();
        const { routeQuery } = useRouteQuery(ctx);
        const handleOnBudgetAccept = () => {
            fetchStructure();
            update();
        };
        const fetchRootStructure = async () => {
            if (hasRole(SystemRole.PLACE_MANAGER)) {
                return;
            }
            const { results } = await getStructuresList({
                parentId: 'null',
                brandId: params.id,
                objectTypeId: objectType.value?.id,
                withBudget: true,
                containPlace: routeQuery.value.q,
            });
            rootStructure.value = results[0];
        };
        watch(routeQuery, () => fetchStructure());
        const resolveSystemRoleParam = () => {
            switch (true) {
                case hasRole(SystemRole.STRUCTURE_MANAGER):
                    return { managedByUserId: authUserId.value };
                case hasRole(SystemRole.PLACE_MANAGER):
                    return {};
                default:
                    return { parentId: rootStructure.value?.id };
            }
        };
        const fetchStructure = async () => {
            try {
                isLoading.value = true;
                await fetchRootStructure();
                const { results } = await getStructuresList({
                    ...resolveSystemRoleParam(),
                    brandId: params.id,
                    objectTypeId: objectType.value?.id,
                    withBudget: true,
                    containPlace: routeQuery.value.q,
                });
                structures.value = results;
            }
            finally {
                isLoading.value = false;
            }
        };
        const onObjectTypeUpdate = async (value) => {
            objectType.value = value;
            await fetchStructure();
        };
        onMounted(async () => {
            const { results } = await getObjectTypeByBudget({
                limit: 1,
                page: 1,
                brandId: params.id,
            });
            objectType.value = results[0];
            await fetchStructure();
        });
        return {
            structures,
            brand,
            objectType,
            fetchStructure,
            onObjectTypeUpdate,
            rootStructure,
            isLoading,
            handleOnBudgetAccept,
            updatesCounter,
        };
    },
});
