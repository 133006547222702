import { EditorContent } from '@tiptap/vue-2';
import { computed, defineComponent, watch, ref, } from '@vue/composition-api';
import { insertUserNames, replaceUserNamesWithIds } from '@/util/mention';
import { useTextEditor } from './_composables/use-text-editor';
import { Btn, BtnTheme } from '@/components/Button';
import { Icon } from '@/components/Icon';
import BoldSvg from '@/assets/icons/bold.svg';
import ItalicSvg from '@/assets/icons/italic.svg';
import StrikethroughSvg from '@/assets/icons/strikethrough.svg';
import AttachSvg from '@/assets/icons/attach.svg';
import MarkSvg from '@/assets/icons/mark.svg';
import BulletListSvg from '@/assets/icons/bullet-list.svg';
import NumberListSvg from '@/assets/icons/number-list.svg';
import LinkSvg from '@/assets/icons/link.svg';
import LinkOffSvg from '@/assets/icons/link-off.svg';
import { useTextEditorLink } from '@/components/WysiwygInput/_composables/use-input-link';
import LinkModal from '@/components/WysiwygInput/_components/LinkModal.vue';
export default defineComponent({
    name: 'Wysiwyg',
    components: {
        LinkModal,
        EditorContent,
        Btn,
        Icon,
    },
    props: {
        value: { type: String, default: '' },
        disabled: Boolean,
        readonly: Boolean,
        required: Boolean,
        hideToolbar: Boolean,
        toolbarOnFocus: Boolean,
        borderless: Boolean,
        dense: Boolean,
        contentLimit: Boolean,
        transparent: Boolean,
        error: {
            type: [Boolean, String],
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        contentClass: {
            type: String,
            default: 'py-2 px-4',
        },
        getMentionSuggestions: {
            type: Function,
            required: true,
        },
        height: {
            type: String,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, ctx) {
        const isToolbarEnabled = ref(false);
        const file = ref(null);
        const handleOutsideClick = () => {
            isToolbarEnabled.value = false;
        };
        const editor = useTextEditor(ctx, {
            content: computed(() => insertUserNames(props.value)),
            readonly: computed(() => props.readonly || props.disabled),
            placeholder: props.placeholder,
            contentClass: computed(() => props.contentClass),
            getMentionSuggestions: props.getMentionSuggestions,
        });
        const { link, onSetLinkSubmit, isLinkModalEnabled, disableLinkModal, handleEnableLinkModal, } = useTextEditorLink(editor);
        const handleActionClick = (button) => {
            if (button.name === 'attachment') {
                return onFileClick();
            }
            if (button.customAction) {
                return button.customAction();
            }
            if (!editor.value) {
                return;
            }
            // @ts-ignore
            editor.value.chain().focus()[button.action](button.content).run();
        };
        watch(() => editor.value?.getHTML(), (newVal) => {
            ctx.emit('input', replaceUserNamesWithIds(newVal ?? ''));
        });
        watch(() => editor.value?.getText(), (newVal) => {
            ctx.emit('input-raw-text', replaceUserNamesWithIds(newVal ?? ''));
        });
        const showToolbar = computed(() => {
            if (props.hideToolbar) {
                return false;
            }
            if (props.toolbarOnFocus) {
                return isToolbarEnabled.value;
            }
            return true;
        });
        const wysiwygClass = computed(() => ({
            disabled: props.disabled,
            error: props.error,
            borderless: props.borderless,
        }));
        const errorMsg = computed(() => typeof props.error === 'string' ? props.error : '');
        const actionButtons = computed(() => {
            return [
                {
                    name: 'bold',
                    action: 'toggleBold',
                    icon: BoldSvg,
                },
                {
                    name: 'italic',
                    action: 'toggleItalic',
                    icon: ItalicSvg,
                },
                {
                    name: 'strike',
                    action: 'toggleStrike',
                    icon: StrikethroughSvg,
                },
                {
                    name: 'attachment',
                    action: 'onFileClick',
                    icon: AttachSvg,
                },
                {
                    name: 'mention',
                    action: 'insertContent',
                    content: '@',
                    icon: MarkSvg,
                },
                {
                    name: 'bulletList',
                    action: 'toggleBulletList',
                    icon: BulletListSvg,
                },
                {
                    name: 'orderedList',
                    action: 'toggleOrderedList',
                    icon: NumberListSvg,
                },
                ...(editor.value?.getText().length || 0 > 0
                    ? [
                        {
                            name: 'link',
                            icon: LinkSvg,
                            customAction: handleEnableLinkModal,
                        },
                    ]
                    : []),
                ...(editor.value?.isActive('link')
                    ? [
                        {
                            name: 'link-off',
                            icon: LinkOffSvg,
                            action: 'unsetLink',
                        },
                    ]
                    : []),
            ];
        });
        const onFileClick = () => {
            if (!file.value) {
                return;
            }
            file.value.value = '';
            file.value.click();
        };
        return {
            onSetLinkSubmit,
            disableLinkModal,
            link,
            handleOutsideClick,
            BtnTheme,
            file,
            wysiwygClass,
            editor,
            errorMsg,
            actionButtons,
            onFileClick,
            showToolbar,
            isToolbarEnabled,
            handleActionClick,
            isLinkModalEnabled,
        };
    },
});
