<template>
  <li class="comment pb-3">
    <p class="font-semibold text-4xs capitalize">
      {{ date(comment.createdAt) }} {{ comment.author.firstName }}
      {{ comment.author.lastName }}
    </p>
    <p
      class="text-2xs italic break-words"
      v-html="getCommentMessage(comment.message, comment.relatedUsers)"
    ></p>
  </li>
</template>
<script>
import { date } from '@/util/formatters';

export default {
  name: 'CommentOffer',
  props: {
    comment: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    date,
    getCommentMessage(message, users = []) {
      let messageWithMentions = message;

      users.forEach(({ id, firstName, lastName }) => {
        messageWithMentions = messageWithMentions.replace(
          new RegExp(`@${id}`, 'g'),
          `<span class="bg-primary text-white px-2 py-0.5 rounded-full whitespace-no-wrap">${firstName} ${lastName}</span>`,
        );
      });

      return messageWithMentions;
    },
  },
};
</script>
<style scoped lang="scss">
.comment {
  @apply flex flex-col leading-5;
}
</style>
