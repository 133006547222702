<template>
  <div class="w-10">
    <popover offset="-50,0">
      <template #trigger="{ attrs, togglePopoverVisibility, isOpened }">
        <tooltip :label="$t('navbar-brand.my-profile')" :disabled="isOpened">
          <btn theme="none" v-bind="attrs" @click="togglePopoverVisibility">
            <icon :icon="mdiAccountCircleOutline" class="fill-gray-darkest" />
          </btn>
        </tooltip>
      </template>
      <showing-menu class="text-sm px-2 text-left" :items="actions">
        <template #prepend>
          <div class="pl-3 pb-2 font-semibold text-xs max-w-40">
            {{ name }}
          </div>
          <div class="border border-gray-light" />
        </template>
      </showing-menu>
    </popover>
  </div>
</template>

<script>
import { Icon } from '@/components/Icon';
import Btn from '@/components/Button/Button';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { mdiAccountCircleOutline } from '@mdi/js';
import { ClientRouteName } from '@/router/router.constants';
import { useAuthUser, useBrand } from '@/composables';
import router from '@/router';
import { computed } from '@vue/composition-api';
import { Popover } from '@/components/Popover';
import ShowingMenu from '@/components/EllipsisMenu/ShowingMenu.vue';

export default {
  components: { ShowingMenu, Btn, Icon, Tooltip, Popover },
  setup(props, ctx) {
    const { brand } = useBrand();
    const { logout, firstName, lastName } = useAuthUser();

    const { isAdmin, isObjektoAdmin, isBrandAdmin, isContractor } =
      useAuthUser();

    const name = computed(() => `${firstName.value} ${lastName.value}`);

    const actions = computed(() => ({
      notification: {
        name: ctx.root.$t('navbar.notifications'),
        handler() {
          navigateToNotifications();
        },
      },
      logout: {
        name: ctx.root.$t('navbar.logout'),
        handler() {
          logout();
        },
      },
    }));

    const navigateToNotifications = () => {
      const appendParams =
        (!isAdmin.value && isBrandAdmin.value) ||
        !isObjektoAdmin.value ||
        !isContractor.value;

      router.push({
        name: ClientRouteName.NOTIFICATIONS,
        ...(appendParams && { params: { id: brand.value.id } }),
      });
    };

    return {
      name,
      ClientRouteName,
      navigateToNotifications,
      firstName,
      lastName,
      actions,
      mdiAccountCircleOutline,
    };
  },
};
</script>
