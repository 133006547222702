<template>
  <div class="field">
    <h2 class="field-title">{{ title }}</h2>
    <p class="field-description">{{ description }}</p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped lang="scss">
.field {
  @apply bg-white rounded-md p-3.5 mt-4;

  &-title {
    @apply text-header pb-1 font-semibold;
  }

  &-description {
    @apply text-sm;
  }
}
</style>
