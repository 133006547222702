export var ClientRouteName;
(function (ClientRouteName) {
    ClientRouteName["BRAND"] = "Brand";
    ClientRouteName["PROFILE"] = "Profile";
    ClientRouteName["NOTIFICATIONS"] = "Notifications";
    ClientRouteName["REPORTS"] = "Reports";
    ClientRouteName["REPORT_DETAILS"] = "ReportDetails";
    ClientRouteName["OFFERS"] = "Offers";
    ClientRouteName["ADD_OFFER"] = "AddOffer";
    ClientRouteName["OFFER_DETAILS"] = "OfferDetails";
    ClientRouteName["EDIT_OFFER"] = "EditOffer";
    ClientRouteName["OBJECT_IN_OFFER_DETAILS"] = "ObjectInOfferDetails";
    ClientRouteName["PLACES"] = "Places";
    ClientRouteName["ADD_PLACE"] = "AddPlace";
    ClientRouteName["PLACE_DETAILS"] = "PlaceDetails";
    ClientRouteName["EDIT_PLACE"] = "EditPlace";
    ClientRouteName["OBJECTS"] = "Objects";
    ClientRouteName["ADD_OBJECT"] = "AddObject";
    ClientRouteName["OBJECT_DETAILS"] = "ObjectDetails";
    ClientRouteName["EDIT_OBJECT"] = "EditObject";
    ClientRouteName["OBJECT_TYPES"] = "ObjectTypes";
    ClientRouteName["MAP"] = "Map";
    ClientRouteName["PLACE_DASHBOARD"] = "PlaceDashboard";
    ClientRouteName["SETTINGS"] = "Settings";
    ClientRouteName["USERS"] = "Users";
    ClientRouteName["ADD_USER"] = "AddUser";
    ClientRouteName["EDIT_USER"] = "EditUser";
    ClientRouteName["USER_DETAILS"] = "UserDetails";
    ClientRouteName["FORMS"] = "Forms";
    ClientRouteName["ADD_ONE_FORM"] = "AddOneForm";
    ClientRouteName["EDIT_ONE_FORM"] = "EditOneForm";
    ClientRouteName["CATEGORIES"] = "Categories";
    ClientRouteName["ADD_CATEGORY"] = "AddCategory";
    ClientRouteName["EDIT_CATEGORY"] = "EditCategory";
    ClientRouteName["ADD_FORM"] = "AddForm";
    ClientRouteName["EDIT_FORM"] = "EditForm";
    ClientRouteName["STRUCTURES"] = "Structures";
    ClientRouteName["ADD_STRUCTURE"] = "AddStructure";
    ClientRouteName["THEME"] = "Theme";
})(ClientRouteName || (ClientRouteName = {}));
