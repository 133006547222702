import { onBeforeMount } from '@vue/composition-api';
import { extend } from 'vee-validate';
import { length, numeric, regex, required } from 'vee-validate/dist/rules';
import { emailRegex, phoneRegex } from '@/util/form';
import { isUrlValid, validateCheckboxValues } from '@/util/validation';
export const useValidation = (ctx) => {
    const { $i18n } = ctx.root;
    // TODO: global solution for validation
    onBeforeMount(() => {
        extend('required', {
            ...required,
            validate(value, params) {
                try {
                    if (parseInt(value) || value === 0) {
                        throw new Error('Value is parsable number instead of pure string');
                    }
                    const parsedValue = JSON.parse(value);
                    return validateCheckboxValues(parsedValue);
                }
                catch (e) {
                    return required.validate(value, params);
                }
                return required.validate(value, params);
            },
            message: $i18n.t('errors.error-empty').toString(),
        });
        extend('numeric', {
            ...numeric,
            message: $i18n.t('errors.error-numeric').toString(),
        });
        extend('length', {
            ...length,
            message: $i18n.t('errors.error-length').toString(),
        });
        extend('regex', {
            ...regex,
            message: $i18n.t('errors.error-regex').toString(),
        });
        extend('email', {
            validate(value) {
                return value.match(emailRegex);
            },
            message: $i18n.t('errors.error-email').toString(),
        });
        extend('phone', {
            validate(value) {
                return value.match(phoneRegex);
            },
            message: $i18n.t('errors.error-phone').toString(),
        });
        extend('url', {
            validate(value) {
                return isUrlValid(value);
            },
            message: $i18n.t('errors.error-url').toString(),
        });
    });
};
