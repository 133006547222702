export var Keys;
(function (Keys) {
    Keys["ARROW_UP"] = "ArrouwUp";
    Keys["ARROW_DOWN"] = "ArrowDown";
    Keys["ENTER"] = "Enter";
    Keys["ESCAPE"] = "Escape";
})(Keys || (Keys = {}));
export var Direction;
(function (Direction) {
    Direction[Direction["DOWN"] = 1] = "DOWN";
    Direction[Direction["UP"] = -1] = "UP";
})(Direction || (Direction = {}));
export const MENTION_CLASS_NAME = 'mention-user';
export const EMPTY_WYSIWYG_VALUE = '<p></p>';
