import { InitialsAvatar } from '@/components/InitialsAvatar';
import EllipsisMenu from '@/components/EllipsisMenu/EllipsisMenu.vue';
import StatusToggler from '@/components/StatusToggler/StatusToggler.vue';
import { computed, defineComponent } from '@vue/composition-api';
import { useMobileView } from '@/composables';
import { ReportStatus } from '@/api/requests/reports';
import { format, isThisYear, isToday } from 'date-fns';
import { StatusButtonVariant } from '@/components/StatusToggler/StatusToggler.constants';
import { REPORT_STATUS_COLORS } from '@/constants/report';
export default defineComponent({
    components: { StatusToggler, EllipsisMenu, InitialsAvatar },
    props: {
        row: {
            type: Object,
            required: true,
        },
        isRowHovered: {
            type: Boolean,
            default: false,
        },
        update: {
            type: Number,
            default: 0,
        },
        statusOptions: {
            type: Array,
            required: true,
        },
        actions: {
            type: Object,
            required: true,
        },
        changeReportStatus: {
            type: Function,
            required: true,
        },
    },
    setup(props) {
        const { isMobileView } = useMobileView();
        const updatedAt = computed(() => {
            const date = new Date(props.row.updatedAt);
            switch (true) {
                case isToday(date):
                    return format(date, 'HH:mm');
                case isThisYear(date):
                    return format(date, 'dd MMM');
                default:
                    return format(date, 'dd/MM/y');
            }
        });
        return {
            isMobileView,
            updatedAt,
            StatusButtonVariant,
            REPORT_STATUS_COLORS,
            ReportStatus,
        };
    },
});
