var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filters-container"},[_c('filter-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var resetFilters = ref.resetFilters;
var filtersApplied = ref.filtersApplied;
return [_c('div',{staticClass:"flex items-center justify-between"},[_c('h1',{staticClass:"text-lg font-semibold"},[_vm._v(_vm._s(_vm.$t('common.filter')))]),(filtersApplied)?_c('btn',{staticClass:"w-26",attrs:{"theme":"secondary","disabled":_vm.disabled},on:{"click":resetFilters}},[_vm._v(" "+_vm._s(_vm.$t('common.reset'))+" ")]):_vm._e()],1),_c('filter-wrapper',{attrs:{"parameter":_vm.FILTER_PARAMS.STRUCTURE,"value":_vm.filters[_vm.FILTER_PARAMS.STRUCTURE]}},[_c('select-input',{staticClass:"mt-2 mb-5",attrs:{"id":"structureId","url":"structures","label":_vm.$t('filters.structure'),"query":{ brandId: _vm.brandId },"placeholder":_vm.$t('filters.structure-placeholder'),"option-label-name":"name","option-value-name":"id","autocomplete":"","disabled":_vm.disabled,"clearable":""},model:{value:(_vm.filters[_vm.FILTER_PARAMS.STRUCTURE]),callback:function ($$v) {_vm.$set(_vm.filters, _vm.FILTER_PARAMS.STRUCTURE, $$v)},expression:"filters[FILTER_PARAMS.STRUCTURE]"}})],1),_c('filter-wrapper',{attrs:{"parameter":_vm.FILTER_PARAMS.USER,"value":_vm.filters[_vm.FILTER_PARAMS.USER]}},[_c('select-input',{staticClass:"mt-2 mb-5",attrs:{"id":"userId","disabled":_vm.disabled,"url":"users","label":_vm.$t('filters.place-admin'),"query":{
          brandId: _vm.brandId,
          systemRole: _vm.SYSTEM_ROLES.PLACE_MANAGER,
        },"placeholder":_vm.$t('filters.place-admin-placeholder'),"autocomplete":"","clearable":"","option-value-name":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var selection = ref.selection;
return [_c('span',[_vm._v(_vm._s(selection.firstName)+" "+_vm._s(selection.lastName))])]}},{key:"option",fn:function(ref){
        var option = ref.option;
return [_c('span',[_vm._v(_vm._s(option.firstName)+" "+_vm._s(option.lastName))])]}}],null,true),model:{value:(_vm.filters[_vm.FILTER_PARAMS.USER]),callback:function ($$v) {_vm.$set(_vm.filters, _vm.FILTER_PARAMS.USER, $$v)},expression:"filters[FILTER_PARAMS.USER]"}})],1),_c('filter-wrapper',{attrs:{"parameter":_vm.FILTER_PARAMS.TAGS,"value":_vm.filters[_vm.FILTER_PARAMS.TAGS]}},[_c('select-input',{staticClass:"mt-2",attrs:{"disabled":_vm.disabled,"query":{ brandId: _vm.brandId, relation: 'place' },"placeholder":_vm.$t('filters.tags-placeholder'),"label":_vm.$t('filters.tags'),"url":"tags","autocomplete":"","multiple":"","option-value-name":"id","option-label-name":"name"},model:{value:(_vm.filters[_vm.FILTER_PARAMS.TAGS]),callback:function ($$v) {_vm.$set(_vm.filters, _vm.FILTER_PARAMS.TAGS, $$v)},expression:"filters[FILTER_PARAMS.TAGS]"}})],1)]}}]),model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }