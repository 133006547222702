<template>
  <with-side-navigations
    :view-width="50"
    :side-bar-width="50"
    side="right"
    footer
  >
    <template #sideNavigation>
      <div>
        <tabs v-model="activeTab">
          <tab
            :name="$t('add-object-view.coordinates')"
            :reference="COORDINATES_TAB"
          >
            <clustered-map
              v-model="coordinates"
              interactive
              height="h-120"
              :disabled-settings="[
                MapSettings.SHOW_OBJECT_NUMBER_LABEL,
                MapSettings.SHOW_PLACE_NUMBER_LABEL,
                MapSettings.SHOW_OBJECT_DIRECTION,
              ]"
              :interactive-marker="{
                icon: routeStartObjectIcon || DEFAULT_OBJECT_MARKER,
                color: routeStartObjectColor,
                directionAngle: form.directionAngle,
              }"
            />
            <clustered-map
              v-if="showMap && coordinates"
              :disabled-settings="[
                MapSettings.SHOW_OBJECT_NUMBER_LABEL,
                MapSettings.SHOW_PLACE_NUMBER_LABEL,
                MapSettings.SHOW_OBJECT_DIRECTION,
              ]"
              :route-start="{
                position: coordinates,
                customIcon: routeStartObjectIcon,
                directionAngle: form.directionAngle,
                markerColor: routeStartObjectColor,
                label: object.internalNumber,
                labelFormatter: (label) => `#${label}`,
                type: MapDataType.OBJECT,
              }"
              :route-end="{
                position: placeCoordinates,
                type: MapDataType.PLACE,
              }"
              height="h-120"
            />
          </tab>
          <tab
            :name="$t('edit-object-view.multimedia')"
            :reference="MULTIMEDIA_TAB"
          >
            <file-uploader
              :list="multimedia"
              type="multimedia"
              target="objects"
            />
          </tab>
          <tab
            :name="$t('edit-object-view.documents')"
            :reference="DOCUMENTS_TAB"
          >
            <file-uploader :list="docs" type="document" target="objects" />
          </tab>
        </tabs>
      </div>
    </template>
    <template>
      <div class="container-fluid bg-neutral">
        <breadcrumbs
          :path="[
            { name: $t('edit-object-view.heading'), url: getObjectsRoute() },
            { name: objectType ? objectType.name : '', url: getObjectsRoute() },
            { name: $t('edit-object-view.edit') },
          ]"
          class="mt-2 mb-1"
        ></breadcrumbs>

        <object-form
          v-if="object"
          id="objectForm"
          :brand="brand"
          :content="object"
          :clear="objectSubmissionEndSuccessfully"
          :coordinates="coordinates"
          enable-object-type-update
          @set-tab="activeTab = $event"
          @set-coordinates="coordinates = $event"
          @on-submit="handleObjectSubmission"
          @on-show-map="showMap = $event"
          @set-place-coordinates="placeCoordinates = $event"
          @set-object-type="selectedObjectType = $event"
          @form-updated="form = $event"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <div class="flex">
          <btn
            :is-loading="objectSubmissionInProgress"
            type="submit"
            form="objectForm"
            class="w-48 mr-3"
          >
            {{ $t('common.save') }}
          </btn>
        </div>
        <btn theme="none" :to="getObjectsRoute()">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </template>
  </with-side-navigations>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import ObjectForm from '@/views/Brand/Objects/Forms/ObjectForm.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
import FileUploader from '@/components/Uploader/FileUploader.vue';
import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';
import {
  COORDINATES_TAB,
  DOCUMENTS_TAB,
  MULTIMEDIA_TAB,
} from '@/store/modules/ui';
import manageTagsMixin from '@/mixins/manageTagsMixin';
import { OBJECT_LOCALIZATION } from '@/constants/object';
import { ClusteredMap, MapSettings } from '@/components/Map';
import { DEFAULT_OBJECT_MARKER } from '@/components/Map/constants/common';
import { MapDataType } from '@/components/Map/types';

export default {
  name: 'EditObject',
  components: {
    ClusteredMap,
    Breadcrumbs,
    Btn,
    ObjectForm,
    Tabs,
    Tab,
    WithSideNavigations,
    FileUploader,
  },
  mixins: [manageTagsMixin],
  data() {
    return {
      objectSubmissionInProgress: false,
      objectSubmissionEndSuccessfully: false,
      activeTab: COORDINATES_TAB,
      coordinates: null,
      MULTIMEDIA_TAB,
      COORDINATES_TAB,
      DOCUMENTS_TAB,
      showMap: false,
      placeCoordinates: null,
      selectedObjectType: null,
      form: {},
    };
  },
  computed: {
    ...mapState('brand', {
      brand: 'details',
    }),
    ...mapState('object', {
      object: 'details',
      objectType: 'objectType',
    }),
    ...mapState('attachment', ['uploadQueue', 'deleteQueue', 'attachments']),
    docs() {
      return [...this.uploadQueue, ...this.attachments.document];
    },
    multimedia() {
      return [...this.uploadQueue, ...this.attachments.multimedia];
    },
    routeStartObjectColor() {
      return this.selectedObjectType?.color;
    },
    routeStartObjectIcon() {
      return this.selectedObjectType?.iconUrl;
    },
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      VueStore.dispatch(
        'object/fetchObjectTypeDetails',
        to.params.objectTypeId,
      ),
      VueStore.dispatch('object/fetchObjectDetails', to.params.objectId),
      VueStore.dispatch('attachment/clearUploadQueue'),
      VueStore.dispatch('attachment/clearDeleteQueue'),
      VueStore.dispatch('attachment/fetchAttachments', {
        id: to.params['objectId'],
        target: 'objects',
        type: 'document',
      }),
      VueStore.dispatch('attachment/fetchAttachments', {
        id: to.params['objectId'],
        target: 'objects',
        type: 'multimedia',
      }),
    ]);

    next();
  },
  watch: {
    object: {
      immediate: true,
      handler(value) {
        this.coordinates = { lat: value.lat, lng: value.lng };
        this.placeCoordinates = { lat: value.place.lat, lng: value.place.lng };
      },
    },
  },
  mounted() {
    this.showMap = this.object.localization === OBJECT_LOCALIZATION.OUTSIDE;
  },
  methods: {
    ...mapActions('object', ['updateObject', 'deleteObjectImage']),
    ...mapActions('attachment', ['saveAttachments', 'deleteAttachments']),
    ...mapActions('tags', ['saveTags']),
    openUserForm(setButton) {
      setButton({
        show: true,
        onClick: () => {
          this.closeUserForm(setButton);
        },
      });
      this.userFormOpened = true;
    },
    getObjectsRoute(objectTypeId = this.objectType?.id) {
      return this.brand && objectTypeId
        ? {
            name: 'Objects',
            params: { id: this.brand.id, objectTypeId },
          }
        : {};
    },
    async handleObjectSubmission(formData, { tagsToDelete, tagsToSave }) {
      this.objectSubmissionEndSuccessfully = false;
      this.objectSubmissionInProgress = true;
      try {
        if (this.object.imageUrl && !formData.imageUrl)
          await this.deleteObjectImage(this.object.id);
        await this.updateObject({
          objectId: this.object.id,
          data: formData,
        });
        if (this.uploadQueue.length) {
          await this.saveAttachments({
            id: this.object.id,
          });
        }
        if (this.deleteQueue.length) await this.deleteAttachments();

        await this.saveTags({
          tagsToDelete,
          tagsToSave,
          objectId: this.object.id,
        });

        this.$toast.success(this.$t('edit-object-view.updated-successfully'));
        this.$router.push(this.getObjectsRoute(formData.objectTypeId));

        this.objectSubmissionEndSuccessfully = true;
      } finally {
        this.objectSubmissionInProgress = false;
      }
    },
  },
  setup() {
    return { DEFAULT_OBJECT_MARKER, MapDataType, MapSettings };
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
.map {
  height: 480px;
  @apply rounded overflow-hidden;
}
</style>
