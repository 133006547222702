import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useBrand, useMobileView, useRouteQuery, useSort } from '@/composables';
import FetchData from '@/components/DataProvider/FetchData.vue';
import { GenericTable, TableStyle } from '@/components/Table/__new__';
import { Checkbox, ListHeaderCheckbox } from '@/components/Inputs';
import { useSelection } from '@/composables/use-selection';
import { TableActions, TablePagination } from '@/views/_components/Table';
import { useAuthUser } from '@/composables/use-auth-user';
import ExpandButton from '@/components/Table/TableGrid/components/ExpandButton.vue';
import { useObjectActions } from '@/views/Brand/Objects/_composables/use-object-actions';
import { mdiChevronDown } from '@mdi/js';
import { updateObjectImportant, } from '@/api/requests/objects';
import ObjectPreview from '@/views/Brand/Objects/ObjectPreview.vue';
import { useObjectStatus } from '@/composables/use-object-status';
import StatusToggler from '@/components/StatusToggler/StatusToggler.vue';
import { Debounce } from '@/constants/debounce';
import PlaceWithPopover from '@/views/_components/PlaceWithPopover.vue';
import { useExpandDetails } from '@/composables';
import TableLoader from '@/views/_components/Table/TableLoader.vue';
import ShowingMenu from '@/components/EllipsisMenu/ShowingMenu.vue';
import { Popover } from '@/components/Popover';
import { Icon } from '@/components/Icon';
import { AddToObjectsGroupModal, ObjectsGroupModal, } from '@/views/Brand/Objects/_components/ObjectsGroupModals';
import { useObjectListActions } from '@/views/Brand/Objects/_composables/use-object-list-actions';
import { ObjectsGroupModalActionType } from '@/views/Brand/Objects/_components/ObjectsGroupModals';
import { SORT } from '@/constants/filters';
import { StarToggle } from '@/components/StarToggle';
export default defineComponent({
    props: {
        groupId: String,
    },
    components: {
        TableLoader,
        PlaceWithPopover,
        ObjectPreview,
        TablePagination,
        TableActions,
        Checkbox,
        ListHeaderCheckbox,
        FetchData,
        TableDisplay: GenericTable(),
        ExpandButton,
        StatusToggler,
        Popover,
        ShowingMenu,
        Icon,
        ObjectsGroupModal,
        AddToObjectsGroupModal,
        StarToggle,
    },
    setup(props, ctx) {
        const { groupId } = props;
        const { brand } = useBrand();
        const { isContractor } = useAuthUser();
        const { isMobileView } = useMobileView();
        const { changeState, selectedEntries } = useSelection('id');
        const { routeQuery, updateQueryParameter } = useRouteQuery(ctx);
        const { sortConfig, sortBy, sort, setSortBy } = useSort();
        const selectAll = ref(false);
        const isGroupObjectsList = !!props.groupId;
        const { showCreateGroupModal, showAddToGroupModal, objectsListsActions, updatesCounter: objectListActionsUpdatesCounter, } = useObjectListActions({ selectedEntries, isGroupObjectsList }, ctx);
        const { getObjectActions, updatesCounter: objectActionsUpdateCounter, update, } = useObjectActions({ isGroupObjectsList }, ctx);
        const updatesCounter = computed(() => objectListActionsUpdatesCounter.value +
            objectActionsUpdateCounter.value);
        const clearSelections = () => {
            selectedEntries.value = [];
            selectAll.value = false;
        };
        const onSelectAll = (results) => {
            changeState(results, selectAll.value);
        };
        const selectedObjectsIds = computed(() => selectedEntries.value.map((object) => object.id));
        watch(selectedEntries, (value) => {
            ctx.emit('input', value);
        }, {
            deep: true,
        });
        watch(routeQuery, (value) => {
            if (value.sortBy && sortBy !== value.sortBy) {
                if (value.sortBy === SORT.NAME_ASC ||
                    value.sortBy === SORT.NAME_DESC ||
                    value.sortBy === SORT.DESCRIPTION_DESC ||
                    value.sortBy === SORT.DESCRIPTION_ASC) {
                    setSortBy(SORT.CREATED_AT_DESC);
                }
                else {
                    setSortBy(value.sortBy);
                }
            }
        });
        watch(sortBy, (value) => {
            updateQueryParameter({
                route: ctx.root.$route,
                parameter: 'sortBy',
                value: { value },
            });
        });
        const columns = computed(() => {
            return [
                {
                    key: 'checkbox',
                },
                {
                    key: 'internalNumber',
                    label: ctx.root.$t('object-list-view.object-number').toString(),
                    cellClass: 'font-bold flex items-center justify-center',
                    sortable: true,
                },
                ...(isMobileView.value
                    ? []
                    : [
                        {
                            key: 'place',
                            label: ctx.root.$t('object-list-view.place-number').toString(),
                            cellClass: 'font-bold flex items-center',
                            sortable: true,
                            hideDefaultContent: true,
                        },
                        {
                            key: 'address',
                            label: ctx.root.$t('object-list-view.address').toString(),
                            formatter: (value, row) => `${row.city}, ${value}`,
                            gridColumn: '1fr',
                            cellClass: 'font-bold flex items-center',
                        },
                        {
                            key: 'objectType',
                            label: ctx.root.$t('object-list-view.type').toString(),
                            formatter: (objectType) => objectType.name,
                            gridColumn: '0.4fr',
                            cellClass: 'flex items-center',
                            sortable: true,
                        },
                    ]),
                {
                    key: 'monthlyPrice',
                    label: ctx.root.$t('object-list-view.price').toString(),
                    formatter: (price) => `${price} PLN`,
                    sortable: true,
                    gridColumn: isMobileView.value ? '1fr' : 'auto',
                    cellClass: 'flex items-center',
                },
                {
                    key: 'internalStatus',
                    label: isMobileView.value
                        ? ''
                        : ctx.root.$t('object-list-view.status').toString(),
                    cellClass: isMobileView.value
                        ? 'pr-0 flex items-center'
                        : 'flex items-center',
                    sortable: true,
                    hideDefaultContent: true,
                },
                {
                    key: 'actions',
                    cellClass: isMobileView.value ? 'pl-0 pr-4' : 'flex items-center',
                },
            ];
        });
        const objectsQuery = computed(() => {
            return {
                ...routeQuery.value,
                groupId,
                brandId: brand.value?.id,
            };
        });
        const { toggleExpandedItems: toggleExpandObjectDetails, isExpanded: isObjectExpanded, } = useExpandDetails((object) => object.id);
        const { getStatusOptions, updateStatus, OBJECT_STATUS_COLORS } = useObjectStatus();
        const changeStatus = async (id, status) => {
            await updateStatus(id, status);
            update();
        };
        const changeImportanceStatus = async (object, value) => {
            await updateObjectImportant(object.id, { isImportant: value });
            update();
        };
        return {
            Debounce,
            isContractor,
            getObjectActions,
            updatesCounter,
            objectsQuery,
            columns,
            changeState,
            selectedEntries,
            selectedObjectsIds,
            selectAll,
            onSelectAll,
            sort,
            sortConfig,
            isObjectExpanded,
            toggleExpandObjectDetails,
            getStatusOptions,
            OBJECT_STATUS_COLORS,
            isMobileView,
            changeStatus,
            mdiChevronDown,
            showCreateGroupModal,
            showAddToGroupModal,
            clearSelections,
            objectsListsActions,
            TableStyle,
            ObjectsGroupModalActionType,
            isGroupObjectsList,
            changeImportanceStatus,
        };
    },
});
