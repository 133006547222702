import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { NotificationCard } from '@/views/Profile/Notifications/_components/NotificationCard';
import { getNotificationSettings, } from '@/api/requests/users';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner.vue';
import { useAuthUser } from '@/composables';
import { getExcludedEmailNotificationAccessRoles } from './utils';
export default defineComponent({
    components: { NotificationCard, LoadingSpinner },
    setup() {
        const { hasRole } = useAuthUser();
        const notificationSettings = ref([]);
        const isLoading = ref(true);
        onMounted(async () => {
            notificationSettings.value = await getNotificationSettings();
            isLoading.value = false;
        });
        const isNotificationAvailableForUserRole = (type) => {
            const excludedRoles = getExcludedEmailNotificationAccessRoles(type);
            return !excludedRoles.some((role) => hasRole(role));
        };
        return {
            notificationSettings,
            isLoading,
            isNotificationAvailableForUserRole,
        };
    },
});
