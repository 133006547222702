import { defineComponent, ref, computed } from '@vue/composition-api';
import { deleteReport, } from '@/api/requests/reports';
import { Icon } from '@/components/Icon';
import EllipsisMenu from '@/components/EllipsisMenu/EllipsisMenu.vue';
import { Btn, BtnTheme } from '@/components/Button';
import { createConfirmModalData } from '@/util/modal';
import { useMobileView, useRouteQuery, useToggle } from '@/composables';
import ChatReportDetails from '@/views/Brand/Applications/Report/Chat/ChatReportDetails/ChatReportDetails.vue';
import ChatParticipants from '@/views/Brand/Applications/Report/Chat/ChatParticipants.vue';
import StatusToggler from '@/components/StatusToggler/StatusToggler.vue';
import { ReportStatus } from '@/api/requests/reports';
import { REPORT_STATUS_COLORS } from '@/constants/report';
import { mdiChevronLeft, mdiChevronUp, mdiChevronDown } from '@mdi/js';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import ChatReportMobileMenu from '@/views/Brand/Applications/Report/Chat/ChatReportMobileMenu/ChatReportMobileMenu.vue';
import { mdiDotsVertical } from '@mdi/js';
import { RECIPIENTS_EVENTS } from '@/views/Brand/Applications/Components/Recipients/recipients.constants';
import { InitialsAvatar } from '@/components/InitialsAvatar';
import { BtnSize } from '@/components/Btn';
import { ReportNavigation } from '@/views/Brand/Applications/Report/Chat/chat-header/components/report-navigation';
export default defineComponent({
    name: 'ChatHeader',
    computed: {
        BtnSize() {
            return BtnSize;
        },
    },
    components: {
        ReportNavigation,
        StatusToggler,
        ChatParticipants,
        Icon,
        EllipsisMenu,
        Btn,
        ChatReportDetails,
        Tooltip,
        ChatReportMobileMenu,
        InitialsAvatar,
    },
    props: {
        report: {
            type: Object,
            required: true,
        },
        firstMessage: {
            type: Object,
            required: true,
        },
        users: {
            type: Array,
            required: true,
        },
        reportPlaces: {
            type: Object,
            required: true,
        },
        reportObjects: {
            type: Object,
            required: true,
        },
        showResourcesBtn: Boolean,
        closeReport: Function,
    },
    setup(props, ctx) {
        const { $i18n, $toast, $router } = ctx.root;
        const { changePage, previousPage } = useRouteQuery(ctx);
        const { isMobileView } = useMobileView();
        const isHovered = ref(false);
        const { state: isDetailsOpened, toggle: toggleDetailsState, disable: disableDetails, } = useToggle(true);
        const { state: isMobileMenuOpened, toggle: toggleMobileMenuState } = useToggle(false);
        const handleToggleMobileMenu = () => {
            if (isDetailsOpened) {
                disableDetails();
            }
            toggleMobileMenuState();
        };
        const handleBackToList = async () => {
            await $router.push({ name: 'Reports' });
            changePage(previousPage.value);
        };
        const arrowIcon = computed(() => isDetailsOpened.value ? mdiChevronUp : mdiChevronDown);
        const ellipsisMenuOptions = {
            edit: {
                name: $i18n.t('common.edit'),
                handler: () => {
                    ctx.emit('edit');
                },
            },
            delete: {
                name: $i18n.t('common.delete'),
                modal: createConfirmModalData($i18n, 'report', props.report.title),
                async handler() {
                    await deleteReport(props.report.id);
                    $toast.success($i18n.t('report.deleted-successfully'));
                    await $router.push({ name: 'Reports' });
                },
            },
        };
        const toggleMenu = () => {
            return isMobileView ? toggleMobileMenuState() : toggleDetailsState();
        };
        const toggleDetailsMobile = () => {
            toggleMobileMenuState();
            toggleDetailsState();
        };
        const closeMenu = () => {
            return isDetailsOpened ? toggleDetailsState() : toggleMobileMenuState();
        };
        return {
            handleBackToList,
            mdiChevronLeft,
            ReportStatus,
            REPORT_STATUS_COLORS,
            isHovered,
            BtnTheme,
            ellipsisMenuOptions,
            isDetailsOpened,
            toggleDetailsState,
            arrowIcon,
            isMobileView,
            isMobileMenuOpened,
            toggleMobileMenuState,
            mdiDotsVertical,
            toggleMenu,
            toggleDetailsMobile,
            closeMenu,
            RECIPIENTS_EVENTS,
            disableDetails,
            handleToggleMobileMenu,
        };
    },
});
