<template>
  <div class="comments-wrapper">
    <comment-module-offer
      :add-comment-handler="
        (message) =>
          addObjectComment({ data: { message }, objectId: object.id })
      "
      users-url="users"
      :query="commentQuery"
      :url="`objects/${object.id}/comments`"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import CommentModuleOffer from '@/components/Comment/CommentModuleOffer';

export default {
  components: { CommentModuleOffer },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  computed: {
    commentQuery() {
      return {
        // TODO probably we should look for users with access to specified object.id rather than brand.id
        accessToBrandId: this.object.brandId,
      };
    },
  },
  methods: {
    ...mapActions('object', ['addObjectComment']),
  },
};
</script>

<style lang="scss" scoped>
.comments-wrapper {
  @apply mt-auto px-4 pb-4 bg-gray-lighter border-t border-gray-light rounded-b-md;
}
</style>
