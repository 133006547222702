<template>
  <div v-click-outside="close">
    <div ref="reference">
      <slot></slot>
    </div>
    <div v-show="showList" ref="popup" class="dropdown">
      <ul id="mention-list-container" class="select-options">
        <infinite-data
          v-slot="{ items }"
          :url="url"
          :query="{ ...query, ...debouncedQuery }"
          enable-query-watcher
          container-selector="#mentionListContainer"
          custom-spinner
          @update="updatePopper"
        >
          <span :data-items-number="(itemsNumber = items.length)"></span>
          <template v-if="items.length > 0">
            <li
              v-for="(user, i) in items"
              :key="i"
              class="select-option"
              @click="select(user)"
            >
              {{ user.firstName }} {{ user.lastName }}
            </li>
          </template>
          <li v-else class="px-3 py-2">{{ $t('common.no-results') }}</li>
        </infinite-data>
      </ul>
    </div>
  </div>
</template>

<script>
import Popper from 'popper.js';
import InfiniteData from '@/components/DataProvider/InfiniteData';
import debounce from 'lodash.debounce';

export default {
  components: {
    InfiniteData,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    inputValue: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      required: true,
    },
    cursorPosition: {
      type: Number,
      default: 1,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      popperInstance: null,
      reference: null,
      popup: null,
      showList: false,
      search: '',
      currentWordStartIndex: 0,
      trigger: '@',
      debouncedQuery: {},
      delay: 500,
    };
  },
  computed: {
    mentionPattern() {
      return `${this.trigger}[a-zA-Z]+`;
    },
  },
  watch: {
    inputValue: {
      immediate: true,
      handler(value, prev) {
        const words = value.slice(0, this.cursorPosition).split(/\s+/);
        const currentChar = value[value.length - 1];
        const currentWord = words[words.length - 1];

        this.currentWordStartIndex = this.cursorPosition - currentWord.length;

        if (prev && prev.length > value.length) {
          this.removeUnusedMentions(value, prev);
        }

        if (currentChar === this.trigger) {
          this.showList = true;
        } else if (currentWord.match(new RegExp(this.mentionPattern))) {
          this.showList = true;

          this.search = currentWord.slice(1);
        } else {
          this.close();
        }
      },
    },
    showList(value) {
      if (value) this.popperInstance.scheduleUpdate();
    },
    search() {
      debounce(() => {
        this.debouncedQuery = {
          ...(this.search && { q: this.search }),
        };
      }, this.delay)();
    },
  },
  mounted() {
    this.reference = this.$refs.reference;
    this.popup = this.$refs.popup;

    this.appendToBody();
    this.setupPopper();
  },
  beforeDestroy() {
    this.popperInstance.destroy();
  },
  methods: {
    appendToBody() {
      this.$el.removeChild(this.popup);
      document.body.appendChild(this.popup);
    },
    setupPopper() {
      this.popperInstance = new Popper(this.reference, this.popup, {
        placement: 'top-start',
        removeOnDestroy: true,
        positionFixed: true,
        onCreate: this.setWidth,
        onUpdate: this.setWidth,
        modifiers: {
          offset: {
            offset: '-10, -10',
          },
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
        },
      });
    },
    setWidth({ instance: { reference, popper } }) {
      popper.style.width = `${reference.offsetWidth}px`;
    },
    close() {
      this.showList = false;
      this.search = '';
    },
    removeUnusedMentions(value, prev) {
      const currentMentions = this.getMentions(value);
      const prevMentions = this.getMentions(prev);

      if (prevMentions.length > currentMentions.length) {
        this.$emit('input', currentMentions);
      }
    },
    getUserNameMention(user) {
      return `@${user.firstName}_${user.lastName}`;
    },
    getMentions(string) {
      const mentions = [];

      for (const mention of this.value) {
        const searchString = this.getUserNameMention(mention);
        const match = string.match(searchString);

        const searchStringMatches = mentions.filter(
          (mention) => this.getUserNameMention(mention) === searchString,
        );

        if (!match || match.length === searchStringMatches.length) continue;

        mentions.push(mention);
      }

      return mentions;
    },
    select(user) {
      const start = this.inputValue.slice(0, this.currentWordStartIndex);
      const end = this.inputValue.slice(this.cursorPosition);

      const newInputValue = `${start}${this.getUserNameMention(user)} ${end}`;

      const prevMentionsCount = this.getMentions(start).length;

      const newValue = [
        ...this.value.slice(0, prevMentionsCount),
        user,
        ...this.value.slice(prevMentionsCount),
      ];

      this.$emit('mention-user', newInputValue);
      this.$emit('input', newValue);

      this.$nextTick(() => {
        this.close();
      });
    },
    updatePopper() {
      if (this.popperInstance) this.popperInstance.update();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../FormElements/mixins/select/style.scss';

.dropdown {
  @apply bg-white z-60 border border-gray-dark rounded py-2 cursor-pointer;
}
</style>
