<template>
  <div class="comments-wrapper">
    <ul>
      <comment-offer
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
      />
    </ul>
  </div>
</template>
<script>
import CommentOffer from './CommentOffer.vue';

export default {
  name: 'CommentListOffer',
  components: { CommentOffer },
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    commentAdded: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    commentAdded(value) {
      if (value) this.scroll();
    },
  },
  mounted() {
    this.scroll();

    this.$nextTick(() => this.$emit('scroll-finished'));
  },
  methods: {
    scroll() {
      this.$el.scrollTop = this.$el.scrollHeight;
    },
  },
};
</script>
<style scoped lang="scss">
.comments-wrapper {
  @apply px-2 overflow-y-scroll text-sm h-full;
}
</style>
