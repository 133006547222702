var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('with-side-navigations',{attrs:{"view-width":50,"side-bar-width":50,"side":"right","footer":""},scopedSlots:_vm._u([{key:"sideNavigation",fn:function(){return [_c('div',[_c('tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('tab',{attrs:{"name":_vm.$t('add-object-view.coordinates'),"reference":_vm.COORDINATES_TAB}},[_c('clustered-map',{attrs:{"interactive":"","height":"h-120","disabled-settings":[
              _vm.MapSettings.SHOW_OBJECT_NUMBER_LABEL,
              _vm.MapSettings.SHOW_PLACE_NUMBER_LABEL,
              _vm.MapSettings.SHOW_OBJECT_DIRECTION ],"interactive-marker":{
              icon: _vm.routeStartObjectIcon || _vm.DEFAULT_OBJECT_MARKER,
              color: _vm.routeStartObjectColor,
              directionAngle: _vm.form.directionAngle,
            }},model:{value:(_vm.coordinates),callback:function ($$v) {_vm.coordinates=$$v},expression:"coordinates"}}),(_vm.showMap && _vm.coordinates)?_c('clustered-map',{attrs:{"disabled-settings":[
              _vm.MapSettings.SHOW_OBJECT_NUMBER_LABEL,
              _vm.MapSettings.SHOW_PLACE_NUMBER_LABEL,
              _vm.MapSettings.SHOW_OBJECT_DIRECTION ],"route-start":{
              position: _vm.coordinates,
              customIcon: _vm.routeStartObjectIcon,
              directionAngle: _vm.form.directionAngle,
              markerColor: _vm.routeStartObjectColor,
              label: _vm.object.internalNumber,
              labelFormatter: function (label) { return ("#" + label); },
              type: _vm.MapDataType.OBJECT,
            },"route-end":{
              position: _vm.placeCoordinates,
              type: _vm.MapDataType.PLACE,
            },"height":"h-120"}}):_vm._e()],1),_c('tab',{attrs:{"name":_vm.$t('edit-object-view.multimedia'),"reference":_vm.MULTIMEDIA_TAB}},[_c('file-uploader',{attrs:{"list":_vm.multimedia,"type":"multimedia","target":"objects"}})],1),_c('tab',{attrs:{"name":_vm.$t('edit-object-view.documents'),"reference":_vm.DOCUMENTS_TAB}},[_c('file-uploader',{attrs:{"list":_vm.docs,"type":"document","target":"objects"}})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex"},[_c('btn',{staticClass:"w-48 mr-3",attrs:{"is-loading":_vm.objectSubmissionInProgress,"type":"submit","form":"objectForm"}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1),_c('btn',{attrs:{"theme":"none","to":_vm.getObjectsRoute()}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")])],1)]},proxy:true}])},[[_c('div',{staticClass:"container-fluid bg-neutral"},[_c('breadcrumbs',{staticClass:"mt-2 mb-1",attrs:{"path":[
          { name: _vm.$t('edit-object-view.heading'), url: _vm.getObjectsRoute() },
          { name: _vm.objectType ? _vm.objectType.name : '', url: _vm.getObjectsRoute() },
          { name: _vm.$t('edit-object-view.edit') } ]}}),(_vm.object)?_c('object-form',{attrs:{"id":"objectForm","brand":_vm.brand,"content":_vm.object,"clear":_vm.objectSubmissionEndSuccessfully,"coordinates":_vm.coordinates,"enable-object-type-update":""},on:{"set-tab":function($event){_vm.activeTab = $event},"set-coordinates":function($event){_vm.coordinates = $event},"on-submit":_vm.handleObjectSubmission,"on-show-map":function($event){_vm.showMap = $event},"set-place-coordinates":function($event){_vm.placeCoordinates = $event},"set-object-type":function($event){_vm.selectedObjectType = $event},"form-updated":function($event){_vm.form = $event}}}):_vm._e()],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }