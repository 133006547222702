import { Icon, IconSize } from '@/components/Icon';
import { defineComponent } from '@vue/composition-api';
import { SortDir } from '@/composables/use-sort';
import { mdiMenuDown } from '@mdi/js';
export default defineComponent({
    components: { Icon },
    props: {
        dir: {
            type: String,
        },
    },
    setup() {
        return { mdiMenuDown, IconSize, SortDir };
    },
});
