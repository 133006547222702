<template>
  <div>
    <ValidationProvider v-slot="{ errors }" rules="required">
      <text-input
        :value="title"
        :label="$t('add-brand-report-view.title')"
        class="my-6"
        :is-error="errors[0]"
        required
        :disabled="loading"
        :input-style="INPUT_STYLE.SPLIT"
        @input="$emit('update-title', $event)"
      ></text-input>
    </ValidationProvider>

    <ValidationProvider v-slot="{ errors }" rules="required">
      <text-input
        :value="description"
        :label="$t('add-brand-report-view.description')"
        class="my-6"
        :is-error="errors[0]"
        required
        :disabled="loading"
        type="textarea"
        :rows="2"
        :input-style="INPUT_STYLE.SPLIT"
        class-name="border-solid border border-gray-light"
        @input="$emit('update-description', $event)"
      ></text-input>
    </ValidationProvider>
  </div>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import TextInput from '@/components/FormElements/TextInput';
import { INPUT_STYLE } from '@/store/modules/ui';
import extendValidationRules from '@/mixins/extendValidationRules';

export default {
  components: {
    TextInput,
    ValidationProvider,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    brandId: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [extendValidationRules],
  data() {
    return {
      INPUT_STYLE,
    };
  },
};
</script>
