<template>
  <div v-if="id" :class="{ sidebar }">
    <fetch-data
      v-slot="{ data, isLoading }"
      :url="url"
      :force-update="forceUpdate"
      @data-deliver="$emit('data-deliver', $event)"
    >
      <div v-if="isLoading" class="h-40 flex items-center justify-center">
        <loading-spinner is-loading class-names="h-12 w-12" />
      </div>
      <div v-else class="table-preview">
        <div class="image-wrapper image-size">
          <image-modal :image-url="data.cdnImageUrl" :alt="getTitle(data)">
            <image-view
              :src="data.cdnImageUrl"
              :size="ImageSize.CARD"
              :alt="getTitle(data)"
            />
          </image-modal>
        </div>
        <div class="w-full pl-4">
          <div
            class="flex sm:flex-row flex-col sm:items-center items-start my-4 sm:mt-1"
          >
            <div class="flex items-center">
              <icon
                :icon="mdiHomeOutline"
                class="text-primary mr-2 flex-shrink-0"
              />
              <p class="" style="word-break: keep-all">
                <span class="text-lg font-semibold mr-2">
                  {{ getTitle(data) }}
                </span>
              </p>
            </div>
            <btn
              v-if="!hideDetailsBtn"
              class="w-30 sm:mt-auto mt-2 sm:ml-2"
              @click="$emit('open-details')"
            >
              {{ $t('common.details') }}
            </btn>
          </div>
          <slot name="details" :data="data"></slot>

          <slot></slot>
        </div>
      </div>
    </fetch-data>
  </div>
</template>
<script>
import { Icon } from '@/components/Icon';
import ImageModal from '@/components/Modal/ImageModal';
import FetchData from '@/components/DataProvider/FetchData';
import LoadingSpinner from '@/components/Loaders/LoadingSpinner';
import { mapState } from 'vuex';
import Btn from '@/components/Button/Button.vue';
import { ImageView, ImageSize } from '@/components/ImageView';
import { mdiHomeOutline } from '@mdi/js';

export default {
  components: {
    LoadingSpinner,
    FetchData,
    ImageModal,
    Icon,
    Btn,
    ImageView,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
    hideDetailsBtn: {
      type: Boolean,
      default: false,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Array,
      default: () => [],
    },
    forceUpdate: {
      type: Number,
      default: 0,
    },
    getTitle: {
      type: Function,
      default: () => '',
    },
  },
  data() {
    return {
      mdiHomeOutline,
      ImageSize,
    };
  },
  computed: {
    ...mapState('user', {
      userBrand: 'brand',
    }),
    detailsLists() {
      const [el] = this.details;
      let lists = this.details;

      if (!Array.isArray(el)) lists = [this.details];

      return lists;
    },
  },
};
</script>
<style scoped lang="scss">
.table-preview {
  @apply flex flex-col items-center;

  @screen sm {
    @apply flex-row items-start;
  }

  .image-size {
    @apply w-50 h-50 min-w-50;
  }

  .image-wrapper {
    @apply flex items-center justify-center;

    .image {
      @apply object-cover rounded flex-shrink-0 flex-grow-0;
    }
  }
}

.sidebar {
  .table-preview {
    @screen sm {
      @apply flex-col items-center;
    }
  }
}
</style>
